<template>
  <div>
    <container :title="$t('boothVideo')">
      <div class="videoContent">
        <div class="addTitle clearfix">
          <div class="fl">
            <span v-if="LOCALE == 'en'"
              >No more than <span class="mainColor">10</span> videos can be
              added</span
            >
            <span v-else
              >添加视频数最多不超过<span class="mainColor">10</span>个</span
            >
          </div>
          <div class="fr">
            <el-button
              v-if="inspectButton('mem:bom:mdi')"
              type="primary"
              size="small"
              class="btn fr"
              @click="addVideo"
              >{{ $t("addVideo") }}</el-button
            >
          </div>
        </div>
        <div class="videoList clearfix">
          <div v-if="inspectButton('mem:bom:mdi')">
            <vuedraggable
              v-model="videoList"
              @end="onEnd"
              forceFallback="true"
              dragClass="dragMove"
            >
              <transition-group>
                <div
                  class="videoItem fl"
                  v-for="(item, index) in videoList"
                  :key="index"
                >
                  <div class="mask dragMove">
                    <i
                      class="el-icon-video-play cursor"
                      @click="handleContent(item)"
                    ></i>
                  </div>
                  <el-image
                    style="width: 100%; height: 220px"
                    :src="item.video_cover_url"
                    fit="cover"
                  ></el-image>
                  <div class="delete cursor" @click.stop="deleteVideo(item)">
                    {{ $t("delete") }}
                  </div>
                  <div class="tc textOverflow">{{ item.video_name }}</div>
                </div></transition-group
              ></vuedraggable
            >
          </div>
          <div v-else>
            <div
              class="videoItem fl"
              v-for="(item, index) in videoList"
              :key="index"
            >
              <div class="mask">
                <i
                  class="el-icon-video-play cursor"
                  @click="handleContent(item)"
                ></i>
              </div>
              <el-image
                style="width: 100%; height: 220px"
                :src="item.video_cover_url"
                fit="cover"
              ></el-image>
              <div class="tc textOverflow">{{ item.video_name }}</div>
            </div>
          </div>
          <div class="nodatas" v-if="videoList.length == 0">
            {{ $t("nodata") }}
          </div>
        </div>
      </div>
    </container>
    <videoPlay ref="vidoePlay"></videoPlay>
    <el-drawer
      size="500px"
      :title="$t('addVideo')"
      :visible.sync="drawerShow"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="videoAll clearfix" v-loading="allLoading">
        <div
          class="drawerItem fl cursor"
          v-for="(item, index) in videoAllList"
          :key="index"
          @click="handleCheck(item, index)"
        >
          <div v-if="item.progress" class="progress">
            <el-progress
              :text-inside="true"
              :stroke-width="12"
              :percentage="item.progress"
            ></el-progress>
          </div>
          <div v-else>
            <div class="mask1" v-if="item.isCheck">
              <i class="el-icon-success"></i>
            </div>
            <el-image
              style="width: 100%; height: 86px"
              :src="item.cover_url"
              fit="cover"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
        </div>
        <div class="fl addDrawer">
          <fileUpload
            ref="uploadComponents"
            :isSingleVideo="true"
            :companyId="USER_INFO.company_id"
            :isTranscode="false"
            :accept="fileUploadOptions.accept"
            @startUpdateVideo="startUpdateVideo"
            @VODcallbackComplete="VODcallbackComplete"
            @uploadProgressVOD="uploadProgressVOD"
            :isDisabled="addDisabled"
          >
            <i class="el-icon-plus"></i>
          </fileUpload>
        </div>
      </div>
      <div class="drawerFooter">
        <el-button
          type="primary"
          size="small"
          class="fr"
          @click="addBoothId"
          style="margin-left: 16px; width: 80px"
          :loading="btnloading"
          >{{ $t("confirm") }}</el-button
        >
        <el-button
          size="small"
          class="fr"
          style="width: 80px"
          @click="drawerShow = false"
          >{{ $t("cancel") }}</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
import boothMixin from "./mixin";
import videoPlay from "@/baseComponents/videoPlay";
import container from "./container";
import fileUpload from "~/baseComponents/fileUpload";

export default {
  mixins: [boothMixin],
  components: { container, videoPlay, vuedraggable, fileUpload },
  props: {
    booth_id: {},
  },
  data() {
    return {
      boothId: "",
      videoList: [],
      videoListOld: [],
      drawerShow: false,
      btnloading: false,
      allLoading: false,
      addDisabled: false,
      videoAllList: [],
      fileUploadOptions: {
        accept: ".mp4, .avi, .rmvb, .ts, .flv, .wmv, video/*",
      },
    };
  },
  watch: {
    booth_id() {
      this.getList();
    },
  },
  methods: {
    addVideo() {
      this.drawerShow = true;
      this.getAllList();
    },
    handleClose() {
      this.drawerShow = false;
    },
    startUpdateVideo(result) {
      this.addDisabled = true;
      this.videoAllList.push({
        isStart: true,
      });
    },
    // 视频
    VODcallbackComplete(result) {
      this.addDisabled = false;
      result.isCheck = true;
      let list = this.videoAllList.splice(
        this.videoAllList.length - 1,
        1,
        result
      );
      console.log(list);
      // this.getAllList(1);
    },
    uploadProgressVOD(result) {
      let videoList = this.videoAllList.concat([]);
      videoList.forEach((item) => {
        if (item.isStart && !item.name) {
          item.progress = parseInt(result.progress);
        }
      });
      this.videoAllList = videoList;
    },
    handleContent(item) {
      this.$refs.vidoePlay.startPlay(item.aliyun_id);
    },
    // 获取展台视频
    async getList() {
      let params = {
        meeting_booth_id: this.booth_id,
      };
      let data = await this.$store.dispatch(
        "baseConsole/booth_videoInquiry",
        params
      );
      if (data.success) {
        this.videoListOld = data.data.video_info_list;
        data.data.video_info_list.forEach((it, index) => {
          it.sortId = it.video_id;
        });
        this.videoList = data.data.video_info_list;
      }

      this.initComplete();
    },
    // 获取公司的列表
    async getAllList(type) {
      this.allLoading = true;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_getCompanyVideo",
          {
            nopage: 1,
            company_id: this.USER_INFO.company_id,
            status: 0,
          }
        );
        if (result.success) {
          this.allLoading = false;
          result.data.forEach((it, index) => {
            if (type && index === 0) {
              it.isCheck = true;
            } else {
              it.isCheck = false;
            }
            this.videoList.forEach((item) => {
              if (it.id == item.video_id) {
                it.isCheck = true;
              }
            });
          });
          this.videoAllList = result.data;
          this.videoAllList.reverse();
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    // 添加展台视频
    async addVideoList(arr, isAdd) {
      let params = {
        booth_id: this.booth_id,
      };
      if (arr) params.order_data = JSON.stringify(arr);
      try {
        let result = await this.$store.dispatch(
          "baseConsole/addBoothVideo",
          params
        );
        if (result.success) {
          if (isAdd) {
            this.$ssTip();
            this.drawerShow = false;
            this.getList();
          } else {
            // this.$csTip();
          }
          this.btnloading = false;
        } else {
          this.btnloading = false;
        }
      } catch (e) {}
    },
    handleCheck(val, index) {
      this.videoAllList[index].isCheck = !this.videoAllList[index].isCheck;
    },
    addBoothId() {
      let arr = [];
      this.btnloading = true;
      this.videoAllList.forEach((it, index) => {
        let obj = {};
        if (it.isCheck) {
          obj.video_id = it.id;
          obj.order = index;
          arr.push(obj);
        }
      });
      if (arr.length > 10) {
        this.$message.error(this.$t("selectVideo10"));
        this.btnloading = false;
        return;
      }
      this.addVideoList(arr, true);
    },
    deleteVideo(item) {
      this.$confirm(this.$t("confirmDelete"), this.$t("delete"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        closeOnClickModal: false,
        type: "warning",
      })
        .then(async () => {
          let result = await this.$store.dispatch("baseConsole/delBoothVideo", {
            booth_video_id: item.booth_video_id,
          });
          if (result.success) {
            this.$delTip();
            this.getList();
          }
        })
        .catch(() => {});
    },
    onEnd(e) {
      let arr = [];
      this.videoList.forEach((it, index) => {
        let obj = {};
        obj.video_id = it.sortId;
        obj.order = index;
        arr.push(obj);
      });
      let sameArr = this.arrayEqual(this.videoListOld, arr);
      if (sameArr) {
        this.addVideoList(arr);
        this.videoListOld = this.videoList;
      }
    },
    arrayEqual(arr1, arr2) {
      for (var i = 0; i < arr1.length; ++i) {
        if (arr1[i].video_id !== arr2[i].video_id) return true;
      }
      return false;
    },
  },
  mounted() {
    //this.getList();
  },
};
</script>

<style lang="less" scoped>
.videoContent {
  // padding:20px
}
.addTitle {
  padding-right: 32px;
  padding-left: 20px;
}
.videoList {
  padding: 13px 0 24px;
  min-height: 300px;
  position: relative;
}

.mask {
  position: absolute;
  background: #000;
  opacity: 0.5;
  z-index: 100;
  width: 100%;
  height: 220px;
  i {
    color: #fff;
    font-size: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    opacity: 0.7;
  }
}
.addDrawer {
  width: 124px;
  height: 86px;
  background: #ffffff;
  border: 1px dashed #c8cdd0;
  margin-left: 16px;
  position: relative;
  i {
    color: #c8cdd0;
    font-size: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.videoItem {
  width: 29%;
  overflow: hidden;
  max-width: 365px;
  margin-left: 36px;
  margin-bottom: 30px;
  position: relative;
  div {
    color: #7d8695;
  }
  .tc {
    margin-top: 10px;
  }
}
.videoAll {
  padding: 30px 30px 100px;
  .drawerItem {
    width: 124px;
    margin-left: 16px;
    margin-bottom: 12px;
    position: relative;
    height: 86px;
    border: 1px solid #ececec;
    .progress {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .mask1 {
    position: absolute;
    background: rgba(31, 41, 46, 0.54);
    z-index: 100;
    width: 100%;
    height: 86px;
    i {
      color: #fff;
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    // &:hover {
    //   opacity: 0.7;
    // }
  }
}
.delete {
  padding: 1px 18px;
  background: #1f292e;
  border-radius: 11px;
  opacity: 0.8;
  position: absolute;
  right: 12px;
  top: 12px;
  color: #fff !important;
  z-index: 120;
}
.drawerFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 30px;
  width: 100%;
  background: #fff;
}
.nodatas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #909399;
}
.image-slot {
  position: relative;
  height: 86px;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -50%);
    color: #999;
  }
}
.dragMove {
  cursor: move !important;
}
/deep/.el-drawer__body {
  overflow-y: auto;
}
</style>