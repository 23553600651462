<template>
  <div>
    <container :title="$t('boothImages')">
      <div class="addImage clearfix">
        <div class="fl">
          <span v-if="LOCALE == 'en'"
            ><span class="mainColor">10</span> pictures at most</span
          >
          <span v-else
            >图集数最多不超过<span class="mainColor">10</span>个</span
          >
        </div>
        <div class="fr">
          <el-button
            v-if="inspectButton('mem:bom:mdi')"
            type="primary"
            size="small"
            style="width: 80px"
            class="btn fr"
            @click="addImage"
            >{{ $t("addGallery") }}</el-button
          >
        </div>
      </div>
      <div class="imageContent">
        <div class="imageData clearfix">
          <div v-if="inspectButton('mem:bom:mdi')">
            <vuedraggable
              v-model="imageList"
              @end="onEnd"
              forceFallback="true"
              dragClass="dragMove"
            >
              <transition-group>
                <div
                  class="iamgeItem fl dragMove"
                  v-for="(it, index) in imageList"
                  :key="index"
                >
                  <div @click="editImageCollection(it)">
                    <el-image
                      class="image"
                      style="width: 100%; height: 220px"
                      :src="it.img_url"
                      fit="cover"
                    ></el-image>
                    <div class="delete cursor" @click.stop="deleteImgSet(it)">
                      {{ $t("delete") }}
                    </div>
                    <div class="num">
                      {{ it.imgSum }} <span v-if="LOCALE == 'zh'">图</span>
                    </div>
                  </div>
                  <div class="div1"></div>
                  <div class="div2"></div>
                  <div class="tc textOverflow">
                    {{ it.name_en | priorFormat(it.name_zh, LOCALE) }}
                  </div>
                </div>
              </transition-group></vuedraggable
            >
          </div>
          <div v-else>
            <div
              class="iamgeItem fl cursor"
              v-for="(it, index) in imageList"
              :key="index"
            >
              <div @click="editImageCollection(it)">
                <el-image
                  class="image"
                  style="width: 100%; height: 220px"
                  :src="it.img_url"
                  fit="cover"
                ></el-image>
                <div class="num">
                  {{ it.imgSum }} <span v-if="LOCALE == 'zh'">图</span>
                </div>
              </div>
              <div class="div1"></div>
              <div class="div2"></div>
              <div class="tc textOverflow">
                {{ it.name_en | priorFormat(it.name_zh, LOCALE) }}
              </div>
            </div>
          </div>

          <div class="nodatas" v-if="imageList.length == 0">
            {{ $t("nodata") }}
          </div>
        </div>
      </div>
    </container>
    <el-drawer size="500px" :visible.sync="drawerShow" direction="rtl">
      <div slot="title" class="drawerTitle">
        {{ drawerTitle }}<span>{{ $t("leastoneitem") }}</span>
      </div>
      <div class="drawerContent clearfix">
        <div>
          <el-form
            :model="nameObj"
            :rules="rules"
            ref="nameForm"
            label-width="0px"
          >
            <el-form-item prop="name_zh">
              <el-input
                size="small"
                v-model="nameObj.name_zh"
                maxlength="32"
                style="margin-bottom: 10px"
                :placeholder="$t('imageSetChinse')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="name_en">
              <el-input
                size="small"
                v-model="nameObj.name_en"
                :placeholder="$t('imageSetEnglish')"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="imgContent">
          <div class="addImgTitle">{{ $t("addImage") }}</div>
          <div v-loading="loading">
            <div
              class="drawerItem fl cursor"
              v-for="(item, index) in imageAllList"
              :key="index"
              @click="handleCheck(item, index)"
            >
              <div v-if="item.progress" class="progress">
                <el-progress
                  :text-inside="true"
                  :stroke-width="12"
                  :percentage="item.progress"
                ></el-progress>
              </div>
              <div v-else>
                <div class="mask1" v-if="item.isCheck">
                  <i class="el-icon-success"></i>
                </div>
                <el-image
                  style="width: 100%; height: 86px"
                  :src="item.url"
                  fit="cover"
                ></el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="fl addDrawer">
          <fileUpload
            ref="uploadComponents"
            @uploadCompleteOSS="uploadComplete"
            :accept="fileUploadOptions.accept"
            :autoUpload="fileUploadOptions.autoUpload"
            @startUploadOSS="startUploadOSS"
          >
            <i class="el-icon-plus"></i>
          </fileUpload>
        </div>
      </div>
      <div class="clearfix drawerFooter" v-if="inspectButton('mem:bom:mdi')">
        <el-button
          type="primary"
          size="small"
          class="fr"
          @click="addBoothImage"
          style="margin-left: 16px; width: 80px"
          :loading="btnloading"
          >{{ $t("confirm") }}</el-button
        >
        <el-button
          size="small"
          class="fr"
          style="width: 80px"
          @click="drawerShow = false"
          >{{ $t("cancel") }}</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import container from "./container";
import vuedraggable from "vuedraggable";
import fileUpload from "~/baseComponents/fileUpload";
import boothMixin from "./mixin";
import { getFileType, getFileName, getFileSize } from "~/baseUtils";
import { priorFormat } from "@/basePlugins/filters";

export default {
  mixins: [boothMixin],
  components: { container, vuedraggable, fileUpload },
  props: {
    booth_id: {},
  },
  data() {
    return {
      imageList: [],
      imageOldList: [],
      drawerShow: false,
      btnloading: false,
      loading: false,
      imageAllList: [],
      selectCollection: {},
      nameObj: {
        name_zh: "",
        name_en: "",
      },
      img_en:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/defaultImageGroup_en.png",
      img_zh:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/defaultImageGroup_zh.png",
      drawerTitle: this.$t("addGallery"),
      fileUploadOptions: {
        accept: ".jpg,.jpeg,.bmp,.png",
        autoUpload: true,
      },
      rules: {
        name_zh: {
          validator: async (rule, value, callback) => {
            if (value == "" && !this.nameObj.name_en) {
              callback(new Error(" "));
            }
          },
        },
        name_en: {
          validator: async (rule, value, callback) => {
            if (value == "" && !this.nameObj.name_zh) {
              callback(new Error(" "));
            }
          },
        },
      },
    };
  },
  watch: {
    booth_id() {
      // console.log(this.booth_id);
      this.getList();
    },
  },

  methods: {
    // 获取展台图片集
    async getList() {
      let params = {
        booth_id: this.booth_id,
        nopage: 1,
      };
      let data = await this.$store.dispatch(
        "baseConsole/booth_pictureCollection",
        params
      );
      if (data.success) {
        this.imageOldList = data.data;
        let default_url = priorFormat(this.img_en, this.img_zh, this.LOCALE);
        data.data.forEach((it, index) => {
          it.sortId = it.collection_id;
          if (it.pic_list.length > 0) {
            it.img_url = it.pic_list.find((item) => item.url).url;
          } else {
            it.img_url = default_url;
          }
          it.imgSum = it.pic_list.length;
        });
        this.imageList = data.data;
      }
      this.initComplete();
    },
    // 获取公司下的图片
    async getAllCompanyImage() {
      this.loading = true;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_getCompanyPicture",
          {
            nopage: 1,
            company_id: this.USER_INFO.company_id,
            picture_type: 1,
            status: 0,
          }
        );
        if (result.success) {
          result.data.forEach((it) => {
            it.isCheck = false;
          });
          this.imageAllList = result.data;
          this.imageAllList.reverse();
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    // 打开新增图片集抽屉
    addImage() {
      this.selectCollection = {};
      this.imageAllList.forEach((item) => {
        item.isCheck = false;
      });
      this.nameObj = {
        name_zh: "",
        name_en: "",
      };
      this.drawerShow = true;
    },
    handleCheck(val, index) {
      this.imageAllList[index].isCheck = !this.imageAllList[index].isCheck;
    },
    startUploadOSS() {
      //   this.imageAllList.push({
      //     isStart: true,
      //   });
    },
    uploadComplete(result) {
      console.log(result);
      let imgArr = [];
      imgArr.push(result);
      this.createImages(imgArr).then(async (images) => {
        try {
          let res = await this.$store.dispatch(
            "baseStore/company_savePicture",
            {
              company_id: this.USER_INFO.company_id,
              data_info: JSON.stringify(images),
            }
          );
          if (res.success) {
            let obj = {
              url: result.url,
              isCheck: true,
              id: res.data_list[0],
            };
            this.imageAllList.push(obj);
            // this.getAllCompanyImage(obj);
          }
        } catch (e) {}
      });
    },
    createImages(files) {
      return new Promise((resolve, reject) => {
        let images = [];
        let dones = 0;
        files.forEach((item) => {
          let img = new Image();
          img.src = item.url;
          img.onload = function () {
            images.push({
              name: getFileName(item.name),
              format: getFileType(item.name),
              size: getFileSize(item.res.size),
              url: item.url,
              width: img.width,
              height: img.height,
            });
            dones++;
            if (dones == files.length) {
              resolve(images);
            }
          };
        });
      });
    },
    // 新增图片集
    async addBoothImage() {
      this.$refs.nameForm.validate(async (valid) => {
        if (valid) {
          this.btnloading = true;
          let arr1 = [];
          let arr = this.imageAllList;
          if (
            !this.selectCollection.collection_id &&
            this.imageList.length >= 10
          ) {
            this.$message.warning(this.$t("astrictGallery"));
            this.btnloading = false;
            return;
          }
          try {
            arr
              .filter((it) => {
                return it.isCheck;
              })
              .forEach((its) => {
                arr1.push(its.id);
              });

            if (arr1.length > 30) {
              this.$message.warning(this.$t("pictureTotal"));
              this.btnloading = false;
              return;
            }
            let params = {
              booth_id: this.booth_id,
              picture_ids: arr1.join(","),
            };
            if (this.selectCollection && this.selectCollection.collection_id) {
              params.collection_id = this.selectCollection.collection_id;
            }
            let _params = Object.assign(params, this.nameObj);
            let data = await this.$store.dispatch(
              "baseConsole/addImageCollection",
              _params
            );
            if (data.success) {
              this.$ssTip();
              this.drawerShow = false;
              this.getList();
            } else if (data.errorcode == 1000) {
              this.$message.error(this.$t("repeatpictruecollection"));
            } else {
            }
            this.btnloading = false;
          } catch (e) {
            this.$seTip();
          }
        }
      });
      this.btnloading = false;
    },
    editImageCollection(item) {
      this.selectCollection = item;
      this.nameObj.name_zh = item.name_zh;
      this.nameObj.name_en = item.name_en;
      this.drawerTitle = this.$t("editGallery");
      this.drawerShow = true;
      this.selectCollectionChange();
    },
    selectCollectionChange() {
      this.imageAllList.forEach((item) => {
        item.isCheck = false;
        if (this.selectCollection.pic_list.length > 0) {
          this.selectCollection.pic_list.forEach((it) => {
            if (item.id == it.picture_id) {
              item.isCheck = true;
            }
          });
        }
      });
    },
    // 删除图片集
    deleteImgSet(item) {
      this.$confirm(this.$t("confirmDelete"), this.$t("delete"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        closeOnClickModal: false,
        type: "warning",
      })
        .then(async () => {
          let result = await this.$store.dispatch(
            "baseConsole/deleteImageCollection",
            {
              collection_id: item.collection_id,
            }
          );
          if (result.success) {
            this.$delTip();
            this.getList();
          }
        })
        .catch(() => {});
    },
    // 排序
    async sortImageList(arr) {
      let params = {
        booth_id: this.booth_id,
      };
      params.order_data = JSON.stringify(arr);
      try {
        let result = await this.$store.dispatch(
          "baseConsole/orderImageCollection",
          params
        );
        if (result.success) {
          // this.$csTip();
        }
      } catch (e) {}
    },
    // 拖拽
    onEnd() {
      let arr = [];
      this.imageList.forEach((it, index) => {
        let obj = {};
        obj.collection_id = it.sortId;
        obj.order = index;
        arr.push(obj);
      });
      let sameArr = this.arrayEqual(this.imageOldList, arr);
      if (sameArr) {
        this.sortImageList(arr);
        this.imageOldList = this.imageList;
      }
    },
    arrayEqual(arr1, arr2) {
      for (var i = 0; i < arr1.length; ++i) {
        if (arr1[i].collection_id !== arr2[i].collection_id) return true;
      }
      return false;
    },
  },
  mounted() {
    //this.getList();
    this.getAllCompanyImage();
  },
};
</script>

<style lang="less" scoped>
.addImage {
  padding-right: 32px;
  padding-left: 20px;
}
.iamgeItem {
  width: 29%;
  // overflow: hidden;
  max-width: 365px;
  margin-left: 36px;
  margin-bottom: 30px;
  position: relative;
  .image {
    z-index: 10;
    border: 1px solid #ececec;
  }
  .div1 {
    width: 100%;
    max-width: 365px;
    height: 220px;
    // margin: 3px;
    top: 4px;
    left: 4px;
    z-index: 8;
    border: 1px solid #c0c4cc;
    position: absolute;
    background: #fff;
  }
  .div2 {
    width: 100%;
    max-width: 365px;
    height: 220px;
    // margin: 3px;
    top: 8px;
    left: 8px;
    z-index: 5;
    border: 1px solid #c0c4cc;
    position: absolute;
  }
  div {
    color: #7d8695;
  }
  .tc {
    margin-top: 10px;
  }
  .num {
    // width: 66px;
    padding: 1px 16px;
    background: #1f292e;
    border-radius: 11px;
    opacity: 0.8;
    position: absolute;
    right: 12px;
    top: 190px;
    z-index: 12;
    color: #fff;
  }
  .delete {
    padding: 1px 18px;
    background: #1f292e;
    border-radius: 11px;
    opacity: 0.8;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 12;
  }
}
.drawerTitle {
  font-size: 16px;
  font-weight: bold;
  span {
    font-size: 12px;
    color: #909399;
    font-weight: 500;
    margin-left: 5px;
  }
}
.addImgTitle {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
}
.drawerContent {
  padding: 0px 30px 100px;
  .imgContent {
    margin-top: 20px;
    .drawerItem {
      width: 124px;
      margin-left: 16px;
      margin-bottom: 12px;
      position: relative;
      height: 86px;
      .progress {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .mask1 {
      position: absolute;
      background: rgba(31, 41, 46, 0.54);
      z-index: 100;
      width: 100%;
      height: 86px;
      i {
        color: #fff;
        font-size: 32px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      // &:hover {
      //   opacity: 0.7;
      // }
    }
  }
}
.addDrawer {
  width: 124px;
  height: 86px;
  background: #ffffff;
  border: 1px dashed #c8cdd0;
  margin-left: 16px;
  position: relative;
  i {
    color: #c8cdd0;
    font-size: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.imageData {
  padding: 13px 0 24px;
  min-height: 300px;
  position: relative;
}
.drawerFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 30px;
  width: 100%;
  background: #fff;
}
.nodatas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #909399;
}
.dragMove {
  cursor: move !important;
}
/deep/.el-drawer__body {
  overflow-y: auto;
}
</style>