<template>
  <div>
    <container :title="$t('boothData')">
      <div class="dataTitle">
        <div class="dataTips">{{$t('uploadMax',{limit:10})}}</div>
        <div class="upBtn">
          <fileUpload
             v-if="inspectButton('mem:bom:mdi')"
            :isDisabled="list.length>=10"
            :accept="'.xls,.doc,.pdf,.docx,.xlsx'"
            @uploadCompleteOSS="uploadSuccess"
            :autoUpload="true"
            @getFileOriginalName="getFileOriginalName"
          >
            <el-button  :disabled="list.length>=10" type="primary" size="small">{{$t('uploadFile')}}</el-button>
          </fileUpload>
        </div>
      </div>
      <div class="dataList">
        <el-table :data="list" height="370" style="width: 100%">
          <el-table-column :label="$t('designation')" width="480">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('uploadTime')" width="">
            <template slot-scope="scope">
              {{ scope.row.updated_time | secondFormat("LLLL") }}
            </template>
          </el-table-column>
          <el-table-column prop="address" width="100" :label="$t('handle')">
            <template slot-scope="scope">
              <el-button type="text"  v-if="inspectButton('mem:bom:mdi')" slot="reference" @click="deleteFile(scope.row.id)"
                >{{$t('delete')}}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </container>
  </div>
</template>

<script>
import boothMixin from "./mixin";
import container from "./container";
import fileUpload from "../fileUpload";
export default {
  mixins: [boothMixin],
  components: { container, fileUpload },
  props: ["booth_id"],
  data() {
    return {
      list: [],
      currentName:''
    };
  },
  mounted() {},
  watch: {
    booth_id() {
      this.getList();
    },
  },
  methods: {
    getFileOriginalName(name){
     
      this.currentName = name;
    },
    deleteFile(e) {
      this.$confirm(`${this.$t('confirmDelete')}?`, this.$t('tipsTitle'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText:this.$t('cancel'),
        type: "warning",
      })
        .then(async () => {
          let data = await this.$store.dispatch("baseConsole/deleteBoothData", {
            file_id: e,
          });
          if (data.success) {
            
            this.getList();
          } else {
           
          }
        })
        .catch(() => {
         
        });
    },
    async uploadSuccess(e) {
      let data = await this.$store.dispatch("baseConsole/addBoothData", {
        booth_id: this.booth_id,
        file_data: JSON.stringify([
          {
            name: this.currentName,
            url: e.url,
            size: e.res.size,
          },
        ]),
      });
      this.currentName = '';
      this.getList();
    },
    async getList() {
      let data = await this.$store.dispatch("baseConsole/getBoothData", {
        nopage: 1,
        booth_id: this.booth_id,
      });
       this.initComplete();
      this.list = data.data;
    },
  },
};
</script>

<style lang="less" scoped>
.dataTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px 0 22px;
  .dataTips {
    font-size: 13px;
    span {
      color: #027fff;
    }
  }
}
</style>
